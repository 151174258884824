import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '@/views/404.vue'
//import Demo from '@/views/Demo.vue'


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'LandingPage',
        meta: {
            title: 'netMedia SR e.U.'
        },
        component: () =>
            import ('@/views/LandingPage.vue')
    },
    /*
    {
        path: '/about',
        name: 'About',
        meta: {
            title: 'About'
        },
        component: () =>
            import ('@/views/About.vue')
    },
    */

    /*
    {
        path: '/parallax-home',
        name: 'ParallaxHome',
        meta: {
            title: 'Parallax Home || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/all-home-version/ParallaxHome.vue')
    },
    */
   /*
    {
        path: '/service',
        name: 'Service',
        meta: {
            title: 'Service || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/service/Service.vue')
    },
    */
    {
        path: '/app-development',
        name: 'ServiceDetails',
        meta: {
            title: 'App Development'
        },
        component: () =>
            import ('@/views/service/AppDevelopment.vue')
    },
    /*
    {
        path: '/blog',
        name: 'Blog',
        meta: {
            title: 'Blog || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/blog/Blog.vue')
    },
    {
        path: '/blog-details',
        name: 'BlogDetails',
        meta: {
            title: 'Blog Details || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/blog/BlogDetails.vue')
    },
    
    {
        path: '/portfolio',
        name: 'Portfolio',
        meta: {
            title: 'Portfolio || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/portfolio/Portfolio.vue')
    },
    {
        path: '/portfolio-details',
        name: 'PortfolioDetails',
        meta: {
            title: 'Portfolio Details || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/portfolio/PortfolioDetails.vue')
    },
    {
        path: '/team',
        name: 'Team',
        meta: {
            title: 'Team || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Team.vue')
    },
    {
        path: '/button',
        name: 'Button',
        meta: {
            title: 'Button || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Button.vue')
    },
    {
        path: '/pricing-plan',
        name: 'PricingPlan',
        meta: {
            title: 'Pricing Plan || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/PricingPlan.vue')
    },
    {
        path: '/accordion-with-tab',
        name: 'AccordionWithTab',
        meta: {
            title: 'Accordion With Tab || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/AccordionWithTab.vue')
    },
    {
        path: '/testimonial',
        name: 'Testimonial',
        meta: {
            title: 'Tesimonial || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Testimonial.vue')
    },
    {
        path: '/column',
        name: 'Column',
        meta: {
            title: 'Column || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Column.vue')
    },
    {
        path: '/list-style',
        name: 'ListStyle',
        meta: {
            title: 'List Style || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/ListStyle.vue')
    },
    
    {
        path: '/contact-form',
        name: 'ContactForm',
        meta: {
            title: 'Contact Form || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/ContactForm.vue')
    },
    
    {
        path: '/video-popup',
        name: 'VideoPopup',
        meta: {
            title: 'Video Popup || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/VideoPopup.vue')
    },
    */
    /*
    {
        path: '/brand',
        name: 'Brand',
        meta: {
            title: 'Brand || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Brand.vue')
    },
    */
    /*
    {
        path: '/counter',
        name: 'Counter',
        meta: {
            title: 'Counter || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Counter.vue')
    },
    */
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: 'Contact || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Contact.vue')
    },
    /*
    {
        path: '/gallery',
        name: 'Gallery',
        meta: {
            title: 'Gallery || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Gallery.vue')
    },
    {
        path: '/progressbar',
        name: 'Progressbar',
        meta: {
            title: 'Progressbar || VueJS Multipurpose Template'
        },
        component: () =>
            import ('@/views/Progressbar.vue')
    },
    */
    {
        path: '*',
        component: Missing,
        meta: {
            title: '404 || VueJS Multipurpose Template'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router